<template>
  <div class="sidebar">
    <span class="sidebar-text">Follow us on social media</span>
    <div class="social-icons-cont">
      <ul>
        <li>
          <a target="_blank" href="https://www.facebook.com/eazydigitalagency">
            <svg
              class="social-icon"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1440 720"
            >
              <path
                d="M1080 362.2c0-198.8-161.2-360-360-360s-360 161.2-360 360c0 179.7 131.6 328.6 303.8 355.6V466.2h-91.4v-104h91.4v-79.3c0-90.2 53.7-140.1 136-140.1 39.4 0 80.6 7 80.6 7v88.6H835c-44.7 0-58.7 27.7-58.7 56.2v67.5h99.8l-16 104.1h-83.9v251.6c172.2-27 303.8-175.9 303.8-355.6z"
                fill="#fff"
              />
            </svg>
          </a>
        </li>
        <li>
          <a target="_blank" href="https://www.instagram.com/eazy.digital">
            <svg
              class="social-icon"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1440 720"
            >
              <path
                d="M720 0c198.7 0 360 161.3 360 360S918.7 720 720 720 360 558.7 360 360 521.3 0 720 0zm0 135c-61.1 0-68.8.3-92.8 1.4-23.9 1.1-40.3 4.9-54.6 10.5-14.8 5.8-27.3 13.4-39.9 26-12.5 12.5-20.2 25.1-26 39.9-5.6 14.3-9.4 30.7-10.5 54.6-1.1 24-1.4 31.7-1.4 92.8s.3 68.8 1.4 92.8c1.1 23.9 4.9 40.3 10.5 54.6 5.8 14.8 13.4 27.3 26 39.9 12.5 12.5 25.1 20.2 39.9 26 14.3 5.6 30.7 9.4 54.6 10.5 24 1.1 31.7 1.4 92.8 1.4s68.8-.3 92.8-1.4c23.9-1.1 40.3-4.9 54.6-10.5 14.8-5.8 27.3-13.4 39.9-26 12.5-12.5 20.2-25.1 26-39.9 5.6-14.3 9.4-30.7 10.5-54.6 1.1-24 1.4-31.7 1.4-92.8s-.3-68.8-1.4-92.8c-1.1-23.9-4.9-40.3-10.5-54.6-5.8-14.8-13.4-27.3-26-39.9-12.5-12.5-25.1-20.2-39.9-26-14.3-5.6-30.7-9.4-54.6-10.5-24-1.1-31.7-1.4-92.8-1.4zm0 40.5c60.1 0 67.2.2 90.9 1.3 21.9 1 33.8 4.7 41.8 7.7 10.5 4.1 18 9 25.9 16.8 7.9 7.9 12.8 15.4 16.8 25.9 3.1 7.9 6.7 19.8 7.7 41.8 1.1 23.7 1.3 30.8 1.3 90.9s-.2 67.2-1.3 90.9c-1 21.9-4.7 33.8-7.7 41.8-4.1 10.5-9 18-16.8 25.9-7.9 7.9-15.4 12.8-25.9 16.8-7.9 3.1-19.8 6.7-41.8 7.7-23.7 1.1-30.8 1.3-90.9 1.3-60.1 0-67.2-.2-90.9-1.3-21.9-1-33.8-4.7-41.8-7.7-10.5-4.1-18-9-25.9-16.8-7.9-7.9-12.7-15.4-16.8-25.9-3.1-7.9-6.7-19.8-7.7-41.8-1.1-23.7-1.3-30.8-1.3-90.9s.2-67.2 1.3-90.9c1-21.9 4.7-33.8 7.7-41.8 4.1-10.5 9-18 16.8-25.9 7.9-7.9 15.4-12.8 25.9-16.8 7.9-3.1 19.8-6.7 41.8-7.7 23.7-1 30.8-1.3 90.9-1.3zm0 69c-63.8 0-115.5 51.7-115.5 115.5S656.2 475.5 720 475.5 835.5 423.8 835.5 360 783.8 244.5 720 244.5zm0 190.5c-41.4 0-75-33.6-75-75s33.6-75 75-75 75 33.6 75 75-33.6 75-75 75zm147.1-195.1c0 14.9-12.1 27-27 27s-27-12.1-27-27 12.1-27 27-27 27 12.1 27 27z"
                fill-rule="evenodd"
                clip-rule="evenodd"
                fill="#fff"
              />
            </svg>
          </a>
        </li>
        <li>
          <a
            target="_blank"
            href="https://www.linkedin.com/company/eazydigital"
          >
            <svg
              class="social-icon"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1440 720"
            >
              <path
                d="M720 0c198.7 0 360 161.3 360 360S918.7 720 720 720 360 558.7 360 360 521.3 0 720 0zM607.4 562.3V281.2H514v281.1h93.4zm337 0V401.1c0-86.4-46.1-126.5-107.6-126.5-49.6 0-71.8 27.3-84.2 46.4v-39.8h-93.4c1.2 26.4 0 281.1 0 281.1h93.4v-157c0-8.4.6-16.8 3.1-22.8 6.7-16.8 22.1-34.2 47.9-34.2 33.8 0 47.3 25.8 47.3 63.6v150.4h93.5zM561.3 145.6c-32 0-52.9 21-52.9 48.6 0 27 20.3 48.6 51.6 48.6h.6c32.6 0 52.9-21.6 52.9-48.6-.5-27.6-20.2-48.6-52.2-48.6z"
                fill-rule="evenodd"
                clip-rule="evenodd"
                fill="#fff"
              />
            </svg>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "Sidebar",
};
</script>

<style lang="scss" scoped>
.sidebar {
  display: none;

  @include breakpoint(xlarge) {
    width: calcRem(74);
    height: calc(100vh - calcRem(80));
    position: fixed;
    left: 0;
    top: calcRem(80);
    background: black;
    border-right: solid calcRem(1) white;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    z-index: 1000;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    //background: red;
  }
}

.social-icon {
  width: 3rem;
  height: 3rem;
}

.sidebar-text {
  font-family: var(--font-josefin);
  text-transform: uppercase;
  font-size: calcRem(13);
  letter-spacing: calcRem(0.2);
  margin-bottom: 2rem;
  color: var(--light-green);

  /* For modern browsers */
  writing-mode: vertical-lr;
  text-orientation: sideways;
  transform: rotate(-180deg);

  /* For Internet Explorer and Microsoft Edge (prior to EdgeHTML 17) */
  writing-mode: tb-rl;
  transform: rotate(-180deg);
}
</style>
