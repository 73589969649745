import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    public: true,
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
    public: false,
  },
  {
    path: "/contact",
    name: "contact",
    component: () =>
      import(/* webpackChunkName: "contact" */ "../views/ContactView.vue"),
    public: true,
  },
  {
    path: "/MNB-munkaltatoi-marka-felmeres-jatekszabalyzat",
    name: "MNBGameEmployerCorp",
    component: () =>
      import(
        /* webpackChunkName: "MNBGameEmployerCorp" */ "../views/MNBGameRulesEmployerView.vue"
      ),
    public: false,
  },
  {
    path: "/MNB-vallalati-marka-felmeres-jatekszabalyzat",
    name: "MNBGameRulesCorp",
    component: () =>
      import(
        /* webpackChunkName: "MNBGameRulesCorp" */ "../views/MNBGameRulesCorpView.vue"
      ),
    public: false,
  },
  {
    path: "/success",
    name: "success",
    component: () =>
      import(/* webpackChunkName: "success" */ "../views/SuccessView.vue"),
    public: false,
  },
  {
    path: "/hu_HU/general-terms-and-conditions",
    name: "generalTermsAndConditions",
    component: () =>
      import(
        /* webpackChunkName: "generalTermsAndConditions" */ "../views/AszfView.vue"
      ),
    public: false,
  },
  {
    path: "/hu_HU/privacy",
    name: "privacy",
    component: () =>
      import(
        /* webpackChunkName: "privacyPolicy" */ "../views/PrivacyView.vue"
      ),
    public: false,
  },
  {
    path: "/general-terms-en",
    name: "generalTermsEn",
    component: () =>
      import(
        /* webpackChunkName: "generalTermsEn" */ "../views/TermsAndConditionsView.vue"
      ),
    public: false,
  },

  // 404 catch all
  {
    path: "/:catchAll(.*)",
    name: "404",
    component: () =>
      import(/* webpackChunkName: "404" */ "../views/404.vue"),
    public: false,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
