<template>
  <HelloSection/>
  <AnimSection/>
  <TrickSection/>
  <SolutionsSection/>
  <ManagementSection/>
  <ClientsSection/>
</template>

<script>
import HelloSection from '@/components/sections/HelloSection.vue';
import AnimSection from '@/components/sections/AnimSection.vue';
import TrickSection from '@/components/sections/TrickSection.vue';
import SolutionsSection from '@/components/sections/SolutionsSection.vue';
import ManagementSection from '@/components/sections/ManagementSection.vue';
import ClientsSection from '@/components/sections/ClientsSection.vue';
export default {
  components: {
     HelloSection, 
     AnimSection, 
     TrickSection, 
     SolutionsSection,
     ManagementSection,
     ClientsSection
  }
}
</script>

<style lang="scss" scoped>
.hello-block {
  max-width: calcRem(500);
  @include breakpoint(xlarge) {
    margin-top: calcRem(160);
  }
}

.subtitle {
  z-index: 100;
  position: relative;
}

.dino {
    width: 100%;
    position: relative;
    margin: 2rem 0;
    z-index: 1;

    @include breakpoint(xlarge) {
        margin: 0;
        width: unset;
        height: 15rem;
        position: absolute;
        right: calcRem(100);
        top: calcRem(200);
    }

}
</style>