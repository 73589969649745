<template>
<div class="page-container">
  <Header>
    <Nav/>
  </Header>
  <Sidebar/>
  <div class="content-wrapper">
    <router-view/>
  </div>
  <Footer/>
</div>
</template>

<script>

import Nav from '@/components/Nav.vue';
import Header from '@/components/Header.vue';
import Sidebar from '@/components/Sidebar.vue';
import Footer from '@/components/Footer.vue';

export default {
  components: {
     Nav, Header, Sidebar, Footer
  },
  head: {
    title: "EAZY Digital | Be digital. Sell More.",
    link: [
      {
        rel: "icon",
        href: "./eazy-favicon.svg"
      }
    ],
    meta: [
      // Basic
      {
        name: "title",
        content: "EAZY Digital | Be digital. Sell More."
      },
      {
        name: "description",
        content: "EAZY Digital is a new wave digital agency founded in Budapest. The largest division is Google and Facebook ad management."
      },
      // Open Graph
      {
        property: "og:url",
        content: "https://eazy.digital/" 
      },
      {
        property: "og:type",
        content: "website" 
      },
      {
        property: "og:title",
        content: "EAZY Digital | Be digital. Sell More." 
      },
      {
        property: "og:description",
        content: "EAZY Digital is a new wave digital agency founded in Budapest. The largest division is Google and Facebook ad management." 
      },
      {
        property: "og:image",
        content: "#" // Missing!
      },
      // Twitter
      // {
      //   name: "twitter:card",
      //   content: "summary_large_image",
      // },
      // {
      //   name: "twitter:domain",
      //   content: "eazy.digital",
      // },
      // {
      //   name: "twitter:url",
      //   content: "https://eazy.digital"
      // },
      // {
      //   name: "twitter:title",
      //   content: "EAZY Digital | Be digital. Sell More."
      // },
      // {
      //   name: "twitter:description",
      //   content: "EAZY Digital is a new wave digital agency founded in Budapest. The largest division is Google and Facebook ad management."
      // },
      // {
      //   name: "twitter:image",
      //   content: "#" // Missing!
      // }
    ]
  }
}
</script>

<style lang="scss">

html {
  font-size: calc(13px + 0.3vw);
  box-sizing: border-box;
  scroll-behavior: smooth;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--font-montserrat);
  background: linear-gradient(90deg, var(--dark-gradient-first), var(--dark-gradient-second), var(--dark-gradient-third), var(--dark-gradient-second), var(--dark-gradient-first));
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--white);
}

.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
}

.content-wrapper{
  flex: 1;
  padding-top: calcRem(80);
  padding-left: calcRem(24);
  padding-right: calcRem(24);
  @include breakpoint(xlarge) {
    padding-top: calcRem(110);
    padding-left: calc(calcRem(74) + 2rem);
  }
}
</style>
